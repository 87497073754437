import store from "@/store/index.js";

export function encode64(data) {
  return btoa(data);
}
export function decode64(data) {
  return new TextDecoder().decode(urlB64ToUint8Array(data));
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i);

  return outputArray;
}

export function processToken(token) {
  let temp = JSON.parse(decode64(token.split(".")[1]));

  try {
    temp.iss = JSON.parse(temp.iss);
  } catch (e) {
    // pass
  }
  return temp;
}

export function humanFileSize(bytes, dp = 1, units = "file") {
  const thresh = 1000;

  let unitArray;
  switch (units) {
    case "file":
      unitArray = ["b", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      break;
    case "length":
      unitArray = ["mm", "m", "km"];
      break;
    case "area":
      unitArray = ["m2", "km2"];
      break;
  }

	if (Math.abs(bytes) < thresh) {
    return bytes + unitArray[0];
  }

  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < unitArray.length - 1
  );

	console.log(bytes, unitArray, u)

  return bytes.toFixed(dp) + " " + unitArray[u];
}

export function parseDate(date, timestamp = false, mes = true, hora = false) {
  if (date == null || date == undefined || date == "") return "";
  let fecha;

  if (!isNaN(Number(date))) fecha = new Date(Number(date));
  else
    fecha = new Date(
      String(date)
        .replaceAll(/\\/g, "")
        .split(" ")[0]
    );

  if (timestamp) {
    fecha.setHours(0, 0, 0, 0);
    return fecha.getTime();
  }

  const MESES = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let day = `0${fecha.getDate()}`.slice(-2);
  let month = mes
    ? MESES[fecha.getMonth()]
    : `0${fecha.getMonth() + 1}`.slice(-2);
  let year = fecha.getFullYear();

  if (hora)
    var HORA = [
      ("00" + fecha.getHours()).slice(-2),
      ("00" + fecha.getMinutes()).slice(-2),
    ].join(":");

  return [[day, month, year].join(" "), HORA].filter((v) => !!v).join(", ");
}

const status = {
  // "Pendiente Revisión": { color: "purple", icon: "mdi-eye" },
  // Rechazado: { color: "error", icon: "mdi-alert-octagon-outline" },
  // Aceptado: { color: "info", icon: "mdi-check" },
  // "Estudio Listo": { color: "green", icon: "mdi-reload" },
  // Completado: { color: "success", icon: "mdi-check-all" },
  // Borrador: { color: "primary", icon: "mdi-eraser" },
  // Incidencia: { color: "#ff4000", icon: "mdi-alert-circle-outline" },
  // Eliminado: { color: "error darken-2", icon: "mdi-trash-can-outline" },
  // Prueba: { color: "blue-grey", icon: "mdi-test-tube" },

  ...store.getters.getEstados
};

export function getSituacionIcon(situacion) {
  return status[situacion] || { color: "grey", icon: "mdi-circle" };
}
// ES0021000010708399HX
export function calcularConsumoAnual(consumos, cups, tarifa) {
  try {
    let datos = consumos
      .filter(({ CodigoCUPS }) => cups.includes(CodigoCUPS))
      .sort((a, b) => (new Date(a.FechaFin) > new Date(b.FechaFin) ? 1 : -1))
      .slice(-12);
    let a1 = null;
    let a2 = null;
    let a3 = null;
    let a4 = null;
    let a5 = null;
    let a6 = null;
    let total = null;

    if (datos.length == 0) {
      return { a1, a2, a3, a4, a5, a6, total };
    }

    datos.forEach(
      ({ Activa1, Activa2, Activa3, Activa4, Activa5, Activa6 }) => {
        total += Activa1 + Activa2 + Activa3 + Activa4 + Activa5 + Activa6;

        a1 += Activa1;
        a2 += Activa2;
        a3 += Activa3;
        a4 += Activa4;
        a5 += Activa5;
        a6 += Activa6;
      }
    );

    let finUltimoConsumo = new Date(datos[datos.length - 1].FechaFin).getTime();

    let inicioUltimoConsumo = new Date(datos[0].FechaInicio).getTime();

    let Y = (finUltimoConsumo - inicioUltimoConsumo) / 864e5;

    a1 = Math.round((a1 / Y) * 365);
    a2 = Math.round((a2 / Y) * 365);
    a3 = Math.round((a3 / Y) * 365);
    a4 = Math.round((a4 / Y) * 365);
    a5 = Math.round((a5 / Y) * 365);
    a6 = Math.round((a6 / Y) * 365);
    total = Math.round((total / Y) * 365);

    "004" != tarifa && "006" != tarifa
      ? ("003" != tarifa && "011" != tarifa) ||
        ((a1 = a1 + a4),
        (a2 = a2 + a5),
        (a3 = a3 + a6),
        (a4 = null),
        (a5 = null),
        (a6 = null))
      : ((a3 = a2), (a2 = null));

    [a1, a2, a3, a4, a5, a6, total] = [a1, a2, a3, a4, a5, a6, total].map((v) =>
      v == 0 ? null : v
    );

    return { a1, a2, a3, a4, a5, a6, total };
  } catch (err) {
    console.error(err);
  }
  // ES0021000010708399HX
  // console.log(total);
}
// ES0208330000000012CR
export function calcularConsumoAnualGas(consumos, cups, tarifa) {
  try {
    let datos = consumos.filter(({ CodigoCUPS }) => cups.includes(CodigoCUPS));

    let auxConsumos = [];
    var FechaFin = datos[datos.length - 1].FechaFin;
    if (FechaFin == null) {
      FechaFin = datos[datos.length - 1].FechaFinMesConsumo;
    }

    var FechaFin2 = FechaFin.split("T");
    var FechaInicio = new Date(FechaFin2[0]);
    FechaInicio =
      FechaInicio.getFullYear() -
      1 +
      "-" +
      ("0" + (FechaInicio.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + FechaInicio.getDate()).slice(-2) +
      "T00:00:00";

    let auxCaudalMedioEnWhAnual = 0;
    let doceUltimosMesesInteracion = datos.length - 12;
    let CaudalHorarioEnWh = 0;
    let CaudalMaximoDiarioEnWh = 0;

    datos.forEach((c, i) => {
      // console.log(c.FechaInicioMesConsumo, FechaInicio)
      if (c.FechaInicioMesConsumo >= FechaInicio) {
        auxConsumos.push(c);
        auxCaudalMedioEnWhAnual += c.ConsumoEnWhP1;
      }
      if (c.CaudalMedioEnWhDia > 0) {
        CaudalHorarioEnWh = c.CaudalMedioEnWhDia;
      }

      if (c.CaudalMaximoDiario > 0) {
        CaudalMaximoDiarioEnWh = c.auxCaudalMaximoDiario;
      }
    });

    let fechaInicio =
      new Date(auxConsumos[0].FechaInicioMesConsumo).getTime() ||
      new Date(auxConsumos[0].FechaInicio).getTime();

    var countDias =
      (new Date(FechaFin).getTime() - fechaInicio) / (1000 * 60 * 60 * 24);

    auxCaudalMedioEnWhAnual =
      Math.round((auxCaudalMedioEnWhAnual / countDias) * 365) / 1000 || 0;

    return {
      auxCaudalMedioEnWhAnual,
      CaudalHorarioEnWh,
    };
  } catch (err) {
    console.error(err);
  }
  // ES0021000010708399HX
  // console.log(total);
}

export async function consumosTotalesClientes() {
  const redondear = (bytes, dp = 3) => {
    const thresh = 1000;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = ["kWh", "MWh", "GWh", "TWh", "PWh", "EWh", "ZWh", "YWh"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  };

  const store = require("@/store/index.js").default;

  let str = store.getters.getDatosEmpresa;

  const { IdUsuario, IdAgente } = str.iss;
  const IdEmpresa = store.getters.getIdEmpresa;

  let { data: contratos } = await axios({
    method: "GET",
    url: `${process.env.VUE_APP_API_URL}/Contrato/GetDTOAllContratos/?Contratacion_FinderFiltersContratacionAPIDTO={"IdEmpresa":${IdEmpresa},"IdUsuario":${IdUsuario},"IdAgente":${IdAgente}}`,
  });

  const cups = processToken(contratos)
    .iss.filter((c) => c.Situacion == "Activado")
    .map((c) => c.CodigoCUPS);

  let { data: datosCups } = await axios({
    url:
      store.getters.getEntorno == "Luz"
        ? "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSips/GetClientesPost"
        : "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSipsGas/GetClientesPost",
    method: "POST",
    data: {
      ListCUPS: cups,
      LoadAllDatosCliente: true,
      LoadConsumos: true,
      IsExist: true,
    },
  });

  let TOTAL = 0;

  datosCups.ClientesSips.forEach((d) => {
    let { total } = calcularConsumoAnual(
      datosCups.ConsumosSips,
      d.CodigoCUPS,
      d.CodigoTarifaATREnVigor
    );
    TOTAL += total;
  });

  // return TOTAL;
  return redondear(TOTAL * 1000);
}

export function downloadFile(file, open = false) {
  let a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  if (
    open &&
    ![
      "iPad",
      "iPhone",
      "iPod",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad Simulator",
      "Macintosh",
      "MacIntel",
      "MacPPC",
      "Mac68K",
      "Pike v7.6 release 92",
      "Pike v7.8 release 517",
    ].includes(navigator.platform)
  )
    a.target = "_blank";
  else a.download = file.name;
  a.click();
}

export function obtenerNombreTarifaML(codTarifa) {
  // case "001": res = "2.0A ML"; break;
  // 		case "003": res = "3.0A ML"; break;
  // 		case "004": res = "2.0DHA ML"; break;
  // 		case "005": res = "2.1A ML"; break;
  // 		case "006": res = "2.1DHA ML"; break;
  // 		case "007": res = "2.0DHS ML"; break;
  switch (codTarifa) {
    case "001":
      return { actual: "2.0TD ML", antes: "2.0A ML" }; //2.0A ML
    case "003":
      return { actual: "3.0TD ML", antes: "3.0A ML" }; //2.0A ML
    case "004":
      return { actual: "2.0TD ML", antes: "2.0DHA ML" }; //2.0A ML
    case "005":
      return { actual: "2.0TD ML", antes: "2.1A ML" }; //2.0A ML
    case "006":
      return { actual: "2.0TD ML", antes: "2.1DHA ML" }; //2.0A ML
    case "007":
      return { actual: "2.0TD ML", antes: "2.0DHS ML" }; //2.0A ML
    case "008":
      return { actual: "2.0TD ML", antes: "2.0A ML" }; //2.0A ML
    case "011":
      return { actual: "6.1TD ML", antes: "3.1A ML" }; //3.1A ML
    case "012":
      return { actual: "6.1TD ML", antes: "6.1A ML" }; //6.1A ML
    case "013":
      return { actual: "6.2TD ML", antes: "6.2 ML" }; //6.2 ML
    case "014":
      return { actual: "6.3TD ML", antes: "6.3 ML" }; //6.3 ML
    case "015":
      return { actual: "6.4TD ML", antes: "6.4 ML" }; //6.4 ML
    case "016":
      return { actual: "6.4TD ML", antes: "6.5 ML" }; //6.5 ML
    case "017":
      return { actual: "6.1B ML", antes: "6.1B ML" }; //6.1B ML
    case "018":
      return { actual: "2.0TD ML" };
    case "019":
      return { actual: "3.0TD ML" };
    case "020":
      return { actual: "6.1TD ML" };
    case "021":
      return { actual: "6.2TD ML" };
    case "022":
      return { actual: "6.3TD ML" };
    case "023":
      return { actual: "6.4TD ML" };
    default:
      return "";
  }
}

export function obtenerNombreTarifaGas(codTarifa) {
  switch (codTarifa) {
    case "11":
      return "1.1";
    case "12":
      return "1.2";
    case "13":
      return "1.3";
    case "21":
      return "2.1";
    case "22":
      return "2.2";
    case "23":
      return "2.3";
    case "24":
      return "2.4";
    case "25":
      return "2.5";
    case "26":
      return "2.6";
    case "1B":
      return "2.1 bis";
    case "2B":
      return "2.2 bis";
    case "3B":
      return "2.3 bis";
    case "4B":
      return "2.4 bis";
    case "5B":
      return "2.5 bis";
    case "6B":
      return "2.6 bis";
    case "31":
      return "3.1";
    case "32":
      return "3.2";
    case "33":
      return "3.3";
    case "34":
      return "3.4";
    case "35":
      return "3.5";
    case "41":
      return "4.1";
    case "42":
      return "4.2";
    case "43":
      return "4.3";
    case "44":
      return "4.4";
    case "45":
      return "4.5";
    case "46":
      return "4.6";
    case "47":
      return "4.7";
    case "A1":
      return "A1";
    case "A2":
      return "A2";
    case "A3":
      return "A3";
    case "B1":
      return "B1";
    case "B2":
      return "B2";
    case "C1":
      return "C1";
    case "C2":
      return "C2";
    case "D1":
      return "D1";
    case "D2":
      return "D2";
    case "M1":
      return "M1";
    case "M2":
      return "M2";
    case "99":
      return "99";
  }
}

export function obtenerNombreTarifa(codTarifa) {
  return obtenerNombreTarifaML(codTarifa).replaceAll(/ ML/g, "");
}

export const perColumnFilter = (value, filter, dataType = "text") => {
  // console.log('FUNCION FILTRO', {filter, value})

  if (
    filter === "" ||
    filter === null ||
    filter === undefined ||
    (Array.isArray(filter) && filter.length == 0)
  )
    return true;
  if (filter === "-") return value === null;
  if (value === "" || value === null || value === undefined) return false;

  const filterText = (filter, value) => {
    value = String(value)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    filter = String(filter)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const isOperation = /=|<|>|!/.test(filter[0]);
    if (isOperation) {
      switch (filter[0]) {
        case "!":
          return !value.includes(filter.substring(1));
        case "=":
          return value == filter.substring(1);
        case ">":
          return Number(value) >= Number(filter.substring(1));
        case "<":
          return Number(value) <= Number(filter.substring(1));
        default:
          return false;
      }
    } else {
      return value.includes(filter);
    }
  };
  const filterDate = (filter, value) => {
    filter = filter.map((f) => {
      let temp = new Date(f);
      temp.setHours(0, 0, 0, 0);
      return temp.getTime();
    });
    value = parseDate(value, true);
    let max = Math.max(...filter);
    let min = Math.min(...filter);
    return max >= value && value >= min;
  };
  const filterSelect = (filter, value) => {
    if (filter.length > 1) return filter.includes(value);
    else return filterText("=" + filter[0], value);
  };
  const filterBool = (filter, value) => filter == value;

  switch (dataType) {
    case "select":
      return filterSelect(filter, value);
    case "date":
      return filterDate(filter, value);
    case "bool":
      return filterBool(filter, value);
    case "text":
    default:
      return filterText(filter, value);
  }
};

export function timeAgo(timestamp, hours = true) {
  // if (timestamp == null) return null;
	let now = Date.now();
  if (!hours) {
    let dateAux = new Date(timestamp);
    dateAux.setHours(0, 0, 0, 0);
    timestamp = dateAux.getTime();
		dateAux = new Date();
    dateAux.setHours(0, 0, 0, 0);
    now = dateAux.getTime();
  }
  const future = timestamp > now;
  const DATE_UNITS = {
    // week: 86400 * 7,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  const getUnitAndValueDate = (secondsElapsed) => {
    secondsElapsed = Math.abs(secondsElapsed);
    for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
      if (secondsElapsed >= secondsInUnit || unit === "second") {
        const value = Math.floor(secondsElapsed / secondsInUnit) * -1;
        return { value, unit };
      }
    }
  };

  const getTimeAgo = () => {
    const rtf = new Intl.RelativeTimeFormat("es", {
      localeMatcher: 'best fit',
      numeric: 'auto',
    });

    const { value, unit } = getUnitAndValueDate(secondsElapsed);
    return rtf.format(future ? Math.abs(value) : Math.abs(value) * -1, unit);
  };

  const secondsElapsed = (now - timestamp) / 1000;
  // const secondsElapsed = Date.now() > timestamp ? (Date.now() - timestamp) / 1000 : (timestamp - Date.now()) / 1000

  if (secondsElapsed >= DATE_UNITS.week) {
    return parseDate(timestamp, false, true, true);
  } else {
    const [first, ...rest] = getTimeAgo(timestamp);
    return [first.toUpperCase(), rest.join("")].join("");
  }
}

export function jsonToCsv(items, csvHeaders, filename) {
  const JStoExcelDate = (date) => {
    const [day, month, year] = date.split(' ')
    return [year, month, day].join('-');
  }

  const csvString = [
    ...items.map(item => {
      let temp = [];
      csvHeaders.forEach(({ key, dataType }) => {
        let valor = (item[key] != null ? String(item[key]) : item[key] || '\u0020').replace('\r\n', '');
        if (dataType == 'date') valor = JStoExcelDate(parseDate(valor, false, false));
        else if (dataType == 'number') valor = valor.replace('.', ',');
        else if (dataType == 'html') valor = valor.replaceAll(/<[^>]*>/g, '').replaceAll(/;/g, '');
        temp.push(valor)
      })
      return temp;
    })
  ];

  const csvFile = new File(['\ufeff', [csvHeaders.map(h => h.alias || h.key), ...csvString].map(e => e.join(";")).join("\n")], `${filename}.csv`, { type: 'text/csv;charset=utf-8;' });

  downloadFile(csvFile);
}

export async function copyText(text) {
  return await navigator.clipboard.writeText(text)
}